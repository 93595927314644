





























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
@Component
/*
A teaser element that can be used as a button/link (if to or href prop are set) or just a headline in a designed box
 */
export default class TeaserButton extends Vue {
  // to for the router link
  @Prop()
  to: Record<string, any>

  // target URL
  @Prop()
  href: Record<string, any>

  // target attribute of the link
  @Prop()
  target: Record<string, any>

  // rel attribute of the link
  @Prop()
  rel: Record<string, any>

  // unique identifier
  @Prop()
  id: Record<string, any>
}
