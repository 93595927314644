





















































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import TeaserButton from '@/components/common/TeaserButton.vue'
import { Action, Mutation } from 'vuex-class'
import userDomain from '@/store/common/userDomain'
import {
  ACCEPT_DATA_PROTECTION_DECLARATION, ACCEPT_TERMS_OF_SERVICE,
  AUTH_WITH_REFRESH,
  GET_CURRENT_DECLARATION_CONTENT, GET_CURRENT_TERMS_OF_SERVICE_CONTENT
} from '@/store/user/actions.type'
import { UserServerObject } from '@/store/user/types'
import { SimpleServerResponse, User } from '@/store/types'
import DeclarationModal from '@/components/common/DeclarationModal.vue'
import { parseUserServerObjectToUser } from '@/common/globals'

@Component({
  components: { DeclarationModal, TeaserButton }
})

export default class Home extends Vue {
  @Action('updateLoggedInUser')
  public UPDATE_LOGGED_IN_USER: (user: User) => Promise<string>

  @Action(AUTH_WITH_REFRESH)
  public loginHSZG: (token: string) => Promise<UserServerObject>

  @Action(GET_CURRENT_DECLARATION_CONTENT)
  public getCurrentDeclarationContent: (locale: string) => Promise<SimpleServerResponse>

  @Action(ACCEPT_DATA_PROTECTION_DECLARATION)
  public acceptDataProtectionDeclaration: (params: UserServerObject['bearer']) => Promise<SimpleServerResponse>

  @Action(GET_CURRENT_TERMS_OF_SERVICE_CONTENT)
  public getCurrentTermsOfServiceContent: (locale: string) => Promise<SimpleServerResponse>

  @Action(ACCEPT_TERMS_OF_SERVICE)
  public acceptTermsOfService: (params: UserServerObject['bearer']) => Promise<SimpleServerResponse>

  @Mutation('setCurrentPage')
  public setCurrentPage: (path: number[]) => void

  termsOfService = ''
  declaration = ''
  private showLogin = false
  private usernameModel = ''
  private usernameState: boolean | null = null
  private passwordModel = ''
  private href: string | undefined = 'https://kompetenzentwicklung.hszg.de/secure_login'

  watch: {
    '$route.query': {
      handler: 'updateQuery';
      immediate: true;
    };
  }

  student: User = {
    username: 'student',
    appUserId: 'xyz',
    roles: ['student'],
    domain: userDomain.STUDENT,
    rights: []
  }

  get correctDeclaration (): string {
    this.getCurrentDeclarationContent(this.$root.$i18n.locale).then(data => {
      this.declaration = data.message
    }, error => {
      this.$root.$emit('alert', this.$i18n.t('warning.error!'), this.$i18n.t('login.' + error.message), true)
    })
    return this.declaration
  }

  get correctTermsOfService (): string {
    this.getCurrentTermsOfServiceContent(this.$root.$i18n.locale).then(data => {
      this.termsOfService = data.message
    }, error => {
      this.$root.$emit('alert', this.$i18n.t('warning.error!'), this.$i18n.t('login.' + error.message), true)
    })
    return this.termsOfService
  }

  declarationSubmit (): void {
    this.acceptDataProtectionDeclaration(localStorage.getItem('bearer') !== null ? JSON.parse(localStorage.getItem('bearer') as string) : undefined).then(() => this.updateQuery(this.$route.query))
    this.$root.$emit('bv::hide::modal', 'declaration-modal-DPDHSZG')
  }

  termsOfServiceSubmit (): void {
    this.acceptTermsOfService(localStorage.getItem('bearer') !== null ? JSON.parse(localStorage.getItem('bearer') as string) : undefined).then(() => this.updateQuery(this.$route.query))
    this.$root.$emit('bv::hide::modal', 'declaration-modal-TOSHSZG')
  }

  loginStudent (): void {
    this.UPDATE_LOGGED_IN_USER(this.student).then(() => {
      this.$emit('close')
      this.$router.push({ name: 'dashboard' })
    })
  }

  updateQuery (query: any): void {
    if (query.token) {
      this.loginHSZG(query.token).then(userServerObject => {
        if (userServerObject.requestStatus === 'NO_DATA_PROTECTION_LOGIN') {
          this.$root.$emit('bv::show::modal', 'declaration-modal-DPDHSZG')
        } else if (userServerObject.requestStatus === 'NO_TERMS_OF_SERVICE_LOGIN') {
          this.$root.$emit('bv::show::modal', 'declaration-modal-TOSHSZG')
        } else {
          this.UPDATE_LOGGED_IN_USER(parseUserServerObjectToUser(userServerObject)).then(firstPage => {
            this.setCurrentPage([0, 0])
            this.$router.push({ name: firstPage })
          })
        }
      })
    }
  }

  created (): void {
    this.updateQuery(this.$route.query)
  }
}
